
body, html {
  margin: 0;
  padding: 0;
  height: 800vh;
  overflow-x: hidden;
  font-family: 'dreamcottage', sans-serif;
  overflow-y: scroll;
  margin-bottom: 5000px;
  font-size: 14px;
}


.square {

  height: 15px;
  width: 15px;
  background-color: transparent;
  border: solid rgb(255, 255, 255);
  box-shadow: 1px 2px 3px rgb(255, 255, 255);
  border-top-width: 0;
  border-left-width: 0;
  border-bottom-width: 2.5px;
  border-right-width: 2.5px;
  transform: translate(-50%, -50%);
}

@font-face {
  font-family: 'dreamcottage';
  src: url('.\/fonts\/dreamcottage.woff2') format('woff2'),
       url('.\/fonts\/dreamcottage.woff') format('woff');
  font-weight: normal ;
  font-style: normal;
}

.slideshow-container {
  position: relative;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
}

.slideshow-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
  opacity: 0;
  transition: opacity 1s ease-in-out;
}

.slideshow-image.active {
  opacity: 1;
}

@font-face {
  font-family: 'bellena';
  src: url('.\/fonts\/bellena.woff2') format('woff2'),
       url('.\/fonts\/bellena.woff') format('woff');
  font-weight: normal ;
  font-style: normal;
}
@font-face {
  font-family: 'classy';
  src: url('.\/fonts\/classy.woff2') format('woff2'),
       url('.\/fonts\/classy.woff') format('woff');
  font-weight: normal ;
  font-style: normal;
}


.app-container {
  position: relative;
  width: 100%;
  height: 100000px; /* Double la hauteur pour permettre le scroll */
  text-align: center;
}

.background-image {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: url('image.jpg') no-repeat center center;
  background-size: cover;
  z-index: -1;
  
}*\/

.landscape-mode {
  display: flex;
  width: 5%;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: black;
  color: white;
  font-size: 12px;
}

.logo{filter: drop-shadow (0px 0px 50px #ffffff);
  filter: drop-shadow (10px 0px 10px #ffffff);
  filter: drop-shadow (10px 0px 30px #ffffff);}

.portrait-mode {
  display: block;
}


.title, .message, .form-container, .button {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: black;
  opacity: 0; /* Commence invisible */
  transition: opacity 0.5s ease, transform 0.5s ease; /* Transition d'opacité et de déplacement */
  text-align: center;
  width: 90%;
  font-size: 20px;
  max-width: 1000px;
  font-family: 'dreamcottage', sans-serif;
  z-index: 999;
}


.message {
  top: 65%;
}

.form-container {
  top: 80%;
  height: 50px;
  border-radius: 50px;
  padding: 0 30px;
  backgroundColor :'rgba(255,255,255,0.5)';
  outline: none;
  border: none;
  font-family: 'dreamcottage', sans-serif;
}
